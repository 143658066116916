<template>
  <v-container fluid>
    <Members></Members>
  </v-container>
</template>

<script>
  import Members from '@/components/Members.vue'

  export default {
    components: {
      Members
    }
  }
</script>