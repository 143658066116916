<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <p class="ml-3">
              Welcome, {{ $auth.user.nickname | capitalize }}
            </p>
          </v-card-title>
          <v-card-text>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Email Address</v-list-item-title>
                <v-list-item-subtitle>{{ $auth.user.email }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <last-activity :loaded="loaded" :profile="profile"></last-activity>
            <member-since :loaded="loaded" :profile="profile"></member-since>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>
            <p class="ml-3">
              {{ $auth.user.nickname | capitalize }}'s Invoice History
            </p>
          </v-card-title>
          <v-card-text v-if="profile.invoices">
            <member-invoices :loaded="loaded" :profile="profile"></member-invoices>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // @ is an alias to /src
  import LastActivity from '@/components/LastActivity.vue'
  import MemberSince from '@/components/MemberSince.vue'
  import MemberInvoices from '@/components/MemberInvoices.vue'

  export default {
    name: 'Home',
    components: {
      LastActivity,
      MemberSince,
      MemberInvoices
    },
    data() {
      return {
        loaded: false,
        profile: {}
      }
    },
    methods: {
      async getUserProfile() {
        const token = await this.$auth.getTokenSilently()
        this.$api.get('/api/user/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(res => {
          this.profile = res.data
          this.loaded = true
        }).catch(e => console.log(e))
      }
    },
    mounted() {
      this.getUserProfile()
    }
  }
</script>