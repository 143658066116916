<template>
  <div>
    <v-skeleton-loader v-if="!loaded" type="list-item-two-line"></v-skeleton-loader>
    <v-list-item v-else two-line>
      <v-list-item-content v-if="profile.lastActivity">
        <v-list-item-title>Last Activity</v-list-item-title>
        <v-list-item-subtitle>{{ profile.lastActivity | fromNow }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
  export default {
    props: [
      'loaded',
      'profile'
    ]
  }
</script>