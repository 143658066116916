<template>
  <v-data-table :headers="headers" :sort-desc="true" sort-by="DateString" :loading="!loaded" :items="profile.invoices" :items-per-page="5" :search="search"
    class="elevation-1">
    <!-- Search Field -->
    <template v-slot:top>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" class="mx-4"></v-text-field>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    name: 'Invoices',
    props: [
      'loaded',
      'profile'
    ],
    data() {
      return {
        search: '',
        headers: [{
            text: 'Invoice Number',
            value: 'InvoiceNumber'
          },
          {
            text: 'Invoice Date',
            value: 'DateString'
          },
          {
            text: 'Sub-Total',
            value: 'SubTotal'
          },
          {
            text: 'Total',
            value: 'Total'
          },
          {
            text: 'Status',
            value: 'Status'
          }
        ],
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>