<template>

  <v-layout align-center class="center--text">
    <v-flex class="d-flex justify-center">
      <v-img class="shrink" contain src="@/assets/bsa_logo.png" transition="scale-transition" width="140" />

    </v-flex>
    <v-flex class="d-flex justify-center">
      <v-btn @click="login" color="secondary">Log in</v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
  export default {
    data() {
      return {
        dialog: true
      }
    },
    methods: {
      login() {
        this.$emit('login')
      }
    }
  }
</script>