<template>
  <v-content>
    <v-row>
      <v-col>
        <p>Members Directory</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table :headers="headers" :sort-desc="false" sort-by="FIRST_NAME" :loading="!members.length"
          :items="members" :items-per-page="10" :search="search" class="elevation-1">
          <!-- Search Field -->
          <template v-slot:top>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" class="mx-4"></v-text-field>
          </template>
          <!-- Last Activity -->
          <template v-slot:item.LAST_ACTIVITY_DATE_UTC="{ item }">
            <span>{{ (item.LAST_ACTIVITY_DATE_UTC ? $moment(item.LAST_ACTIVITY_DATE_UTC).fromNow() : '' )}}</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>
  export default {
    name: 'Members',
    props: {
      msg: String
    },
    methods: {
      async getMemberData() {
        const token = await this.$auth.getTokenSilently()
        this.$api.get('/api/insightly/contacts', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }).then(res => {
          this.members = res.data
          this.loaded = true
        }).catch(e => console.log(e))
      }
    },
    data() {
      return {
        search: '',
        headers: [{
            text: 'First Name',
            value: 'FIRST_NAME'
          },
          {
            text: 'Last Name',
            value: 'LAST_NAME'
          },
          {
            text: 'Last Activity',
            value: 'LAST_ACTIVITY_DATE_UTC'
          }
        ],
        members: []
      }
    },
    mounted() {
      this.getMemberData()
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>