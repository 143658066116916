import dotenv from 'dotenv'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

dotenv.config()

// Third party libraries
import axios from 'axios';
import lodash from 'lodash';
import moment from 'moment';
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  }
});

// Setup Axios
const axiosConfig = {
  baseURL: process.env.VUE_APP_API_URL,
};

const couchConfig = {
  baseURL: process.env.VUE_APP_DB_URL,
};

const api = axios.create(axiosConfig);
const couch = axios.create(couchConfig);

Object.defineProperty(Vue.prototype, '$api', { value: api });
Object.defineProperty(Vue.prototype, '$couch', { value: couch });

// Lodash setup
Object.defineProperty(Vue.prototype, '$lodash', { value: lodash });
Object.defineProperty(Vue.prototype, '$moment', { value: moment });

// Vue setup
Vue.config.productionTip = false

Vue.filter('capitalize', function(value) {
  return lodash.capitalize(value)
})

Vue.filter('fromNow', function(value) {
  return moment(value).fromNow()
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
