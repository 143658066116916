<template>
  <div v-if="!$auth.loading">
    <v-app v-if="!$auth.isAuthenticated">
      <v-container fluid class="fill-height splash">
        <login-screen @login="login"></login-screen>
      </v-container>
    </v-app>
    <v-app v-else>
      <!-- App Bar -->
      <v-app-bar app color="primary" dark>
        <div class="d-flex align-center">
          <v-img alt="Vuetify Logo" class="shrink mr-2 hidden-md-and-up" contain src="@/assets/bsa_logo.png"
            transition="scale-transition" width="40" />

          <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
            src="@/assets/bsa_logo_long.png" width="210" />
        </div>

        <v-spacer></v-spacer>

        <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
          <span class="mr-2">Latest Release</span>
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-app-bar>

      <!-- Nav Drawer -->
      <v-navigation-drawer app permanent expand-on-hover>
        <v-list-item>
          <v-list-item-avatar style="left: -8px;">
            <v-img :src="$auth.user.picture"></v-img>
          </v-list-item-avatar>
          <v-list-item-title>{{ $auth.user.nickname | capitalize }}</v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/">Home</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-contacts</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/contacts">Members Directory</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link class="d-none">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              <router-link to="/about">About</router-link>
            </v-list-item-title>
          </v-list-item>
          <v-list-item link>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title @click="logout">
              <v-list-item-action>Logout</v-list-item-action>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Main Content -->
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import LoginScreen from './components/LoginScreen.vue';
  export default {
    name: 'App',
    components: {
      LoginScreen
    },
    data: () => ({
      //
    }),
    methods: {
      // Log the user in
      login() {
        this.$auth.loginWithRedirect();
      },
      // Log the user out
      logout() {
        this.$auth.logout({
          returnTo: window.location.origin
        });
      }
    }
  };
</script>

<style scoped>
  .splash {
    background: url('../src/assets/background-home.jpg');
    background-size: cover;
    background-position: center center;
  }
</style>